<template>
  <b-card no-body>
    <b-row class="m-2">
      <b-col>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Top-Up Your Account</h2>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :to="{ name: 'buy-credits' }"
            class="rounded-pill"
            variant="primary"
        >
            <feather-icon
            icon="ArrowLeftCircleIcon"
            class="mr-50"
            />
            <span class="align-middle">Back</span>
        </b-button>
        </div>
        <p class="mt-1 mb-2">Follow the instructions to top-up your account</p>
        <div class="table-responsive">
          <strong>Send the amount to the following bank account</strong>
          <table class="table">
            <tbody>
              <tr>
                <td>Account Name</td>
                <th>Petroline East Africa Ltd</th>
              </tr>
              <tr>
                <td>Account (KSH)</td>
                <th>01136369258600</th>
              </tr>
              <tr>
                <td>Bank Name</td>
                <th>Co-operative Bank of Kenya</th>
              </tr>
              <tr>
                <td>Branch</td>
                <th>Nanyuki</th>
              </tr>
              <tr>
                <td>Swift Code</td>
                <th>KCOOKENA</th>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
  },
};
</script>
